import { TammiDatetime, TammiDatetimeLocale } from "../modules/TammiDatetime";

export interface Beneficiary {
    birthDate: TammiDatetime;
    email: string;
    identificationType: BenefiaryIdentificationType;
    nationality: string;
    name: string;
    phoneNumber: string;
    reason: BeneficiaryReason;
    reasonDescription: string;
    reasonPercentage: string;
    ssn: string;
}

export interface BeneficiaryError {
    birthDate: string;
    email: string;
    nationality: string;
    name: string;
    phoneNumber: string;
    reasonDescription: string;
    reasonPercentage: string;
    ssn: string;
}

export const BeneficiaryErrorInitialValue: BeneficiaryError = {
    birthDate: '',
    email: '',
    name: '',
    nationality: '',
    phoneNumber: '',
    reasonDescription: '',
    reasonPercentage: '',
    ssn: ''
};

export enum BenefiaryIdentificationType {
    BirthDate,
    SocialSecurityNumber
}

export enum BeneficialOwner {
    BoardOfDirectors = 'board-of-directors',
    BoardOfDirectorsAndCeo = 'board-of-directors-and-ceo',
    GeneralOrManagingPartnet = "general-or-managing-partnet",
    Other = 'other'
}
export enum BeneficiaryReason {
    OwnershipOrControl = 'ownership-or-control',
    OtherBasis = "other-basis"
}

export const BenefiaryInitialValue: Beneficiary = {
    birthDate: TammiDatetime.currentDate(TammiDatetimeLocale.EuropeFinland).setDay(1).setMonth(1),
    email: '',
    identificationType: BenefiaryIdentificationType.SocialSecurityNumber,
    name: '',
    nationality: '',
    phoneNumber: '',
    reasonPercentage: '',
    reason: BeneficiaryReason.OwnershipOrControl,
    reasonDescription: '',
    ssn: ''
};