import React from 'react';
import "./FileUpload.scss";
import { v4 as uuidv4 } from 'uuid';
import cloudComputingIcon from '../assets/cloud-computing.svg';
import { TammiArray } from '../modules/TammiArray';
import { FileData } from '../FileData';

export enum FileUploadMimeType {
    Pdf = 'application/pdf'
}

interface FileUploadButtonParams {
    onClick: () => void;
    text: string;
}

const FileUploadMimeTypesForFileInput = {
    [FileUploadMimeType.Pdf]: '.pdf'
};

const filesizeFormatted = (value: number) => {
    return (value / (1024 * 1024)).toFixed(2).replace('.', ',').toString() + 'Mt/MB';
}
const getName = (file: Blob) => {
    return (file as any).name || (file as any).text || 'Nimetön';
}

interface FileReadError {
    error: string;
}
interface FileReadSuccess {
    content: string;
}

const readFileData = (file: Blob) => {
    return new Promise<FileReadError | FileReadSuccess>(resolve => {
        const reader = new FileReader();

        reader.onerror = () => {
            resolve({ error: '' });
        }
        reader.onload = event => {
            const data = event.target!.result as string;

            resolve({ content: data });
        };

        reader.readAsDataURL(file);
    });
}

export const FileUploadButton = ({ onClick, text }: FileUploadButtonParams) => {
    return <button className='FileUploadButton' onClick={onClick}>{text}</button>;
}

interface FileUploadParams {
    allowedMimeTypes: FileUploadMimeType[];
    descriptionForButton: string;
    descriptionForDrop: string;
    onChange: (files: FileData[]) => void;
    summaryOfFiles: (totalSize: string) => string;
}

const getFileDatas = async (blobs: Blob[]) => {
    const newBlobsWithId = await Promise.all(
        blobs.map(async blob => ({
            id: uuidv4(),
            name: getName(blob),
            data: await readFileData(blob),
            size: blob.size
        }))
    );

    let newFiles: FileData[] = [];

    for (const newBlob of newBlobsWithId) {
        if ('error' in newBlob.data) {
            return null;
        }

        newFiles.push({
            data: newBlob.data.content,
            id: newBlob.id,
            name: newBlob.name,
            size: newBlob.size
        });
    }

    return newFiles;
}

export const FileUpload = ({ allowedMimeTypes, descriptionForButton, descriptionForDrop, onChange, summaryOfFiles }: FileUploadParams) => {
    const [files, setFiles] = React.useState<FileData[]>([]);

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        const callback = async (event: Event) => {
            if (event.target) {
                const newBlobs: Blob[] = [...(event.target as any).files];

                if (newBlobs.length > 0) {
                    const newFiles = await getFileDatas(newBlobs);

                    if (newFiles === null)
                        return;

                    const filesCombined = [...files, ...newFiles];

                    setFiles(filesCombined);
                    onChange(filesCombined);
                }
            }
        }

        if (fileInputRef.current) {
            fileInputRef.current.removeEventListener('input', callback);

            fileInputRef.current.addEventListener('input', callback);
        }
    }, [files]);

    return (
        <div className='FileUpload-container'
            onDragOver={event => {
                event.preventDefault();
            }}
            onDrop={async event => {
                event.preventDefault();

                const newBlobs: Blob[] = [...(event.dataTransfer as any).files];

                const newBlobsFiltered = newBlobs.filter(
                    blob => allowedMimeTypes.includes(blob.type as FileUploadMimeType)
                );

                if (newBlobsFiltered.length > 0) {
                    const newFiles = await getFileDatas(newBlobsFiltered);

                    if (newFiles === null)
                        return;

                    const filesCombined = [...files, ...newFiles];

                    setFiles(filesCombined);
                    onChange(filesCombined);
                }
            }}
        >
            {files.length === 0 &&
                <img className='FileUpload-icon' src={cloudComputingIcon} alt='Pilvi-ikoni' />
            }

            <div className='FileUpload-entry-container'>
                {files.map(blob =>
                    <div key={blob.id} className='FileUpload-entry'>
                        <div></div>
                        <div>
                            <p className='FileUpload-entry-name'>{blob.name} ({filesizeFormatted(blob.size)})</p>
                        </div>
                        <div>
                            <p className='FileUpload-close-button' onClick={() => {
                                const newFiles = TammiArray.findElementFirstAndRemove(files, file => file.id === blob.id);

                                setFiles(newFiles);
                                onChange(newFiles);

                                if (newFiles.length === 0) {
                                    if (fileInputRef.current) {
                                        fileInputRef.current.value = '';
                                    }
                                }
                            }}>+</p>
                        </div>
                    </div>
                )}
                {files.length > 0 &&
                    <p className='FileUpload-description-drop'>{
                        summaryOfFiles(filesizeFormatted(files.reduce((total, { size }) => total + size, 0)))
                    }</p>
                }
            </div>

            <div className='FileUpload-texts-container'>
                {files.length === 0 &&
                    <>
                        <p className='FileUpload-description-drop'>{descriptionForDrop}</p>

                        <hr />

                        <p className='FileUpload-description-button'>{descriptionForButton}</p>
                    </>
                }
                <div style={{ justifyContent: 'center', display: 'flex', marginBottom: 16 }}>
                    <div>
                        <FileUploadButton
                            onClick={() => {
                                if (fileInputRef.current) {
                                    fileInputRef.current.click();
                                }
                            }}
                            text='Valitse tiedostot / Select files' />
                    </div>
                </div>
            </div>

            <input ref={fileInputRef}
                type='file'
                multiple
                accept={allowedMimeTypes.map(type => FileUploadMimeTypesForFileInput[type]).join(',')}
                style={{ display: 'none' }} />
        </div>
    );
}