import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { BeneficiaryAccordion } from "../components/BeneficiaryAccordion";
import { ButtonPrimary } from "../components/Button";
import { FieldSingleLineText, FieldCheckboxWithLabel, FieldRadioButtonWithLabel, FieldMultilineText } from "../components/Field";
import { FieldContainer } from "../components/FieldContainer";
import { FileUpload, FileUploadMimeType } from "../components/FileUpload";
import { Label, LabelMini } from "../components/Label";
import { Paragraph } from "../components/Paragraph";
import { PoliticallyExposedPersonAccordion } from "../components/PoliticallyExposedPersonAccordion";
import { TitleMain, TitleFormSection } from "../components/Title";
import { FileData } from "../FileData";
import { TammiArray } from "../modules/TammiArray";
import { TammiDatetime, TammiDatetimeFormat } from "../modules/TammiDatetime";
import { TammiFormMessage, TammiFormValidation } from "../modules/TammiForm";
import { TammiNumber } from "../modules/TammiNumber";
import { TammiObject } from "../modules/TammiObject";
import { TammiSet } from "../modules/TammiSet";
import { TammiUrl } from "../modules/TammiUrl";
import { BeneficialOwner, Beneficiary, BeneficiaryReason, BenefiaryInitialValue, BenefiaryIdentificationType, BeneficiaryError, BeneficiaryErrorInitialValue } from "../values/Beneficiary";
import { CountriesSelected } from "../values/Country";
import { FormPostRequestBody, FormPostResponseErrorMessageEnumType, FormPostResponseFailure, FormPostResponseSuccess } from "../values/Form";
import { PoliticallyExposedPerson, PoliticallyExposedPersonError, PoliticallyExposedPersonErrorInitialValue, PoliticallyExposedPersonInitialValue } from "../values/PoliticallyExposedPerson";

const Error = TammiFormMessage.Error({
    className: 'App-Error'
});

interface FormPageParams {
    contactEmail: string;
    onSuccess: () => void;
    serverUrl: string;
}
export const FormPage = ({ contactEmail, onSuccess, serverUrl }: FormPageParams) => {
    const [attachments, setAttachments] = React.useState<FileData[]>([]);
    /* const [form, setForm] = React.useState({
        beneficialOwners: BeneficialOwner.Other,
        beneficiaries: [] as (Beneficiary & { id: string })[],
        businessOutsideEurope: false,
        businessOutsideEuropeCountries: new Set<string>([]),
        companyId: '439734',
        concernGraphToBeAdded: false,
        controlOf25Percent: false,
        name: 'Henri Korpela',
        politicallyExposedPersons: [] as (PoliticallyExposedPerson & { id: string })[],
        publicSignificantTaskDuring12Months: false,
        registeredToFinland: false,
        representatives: 'Representatives',
        representativeNationality: 'suomalainen',
        representativePassportAuthority: 'auktoriteetti',
        representativePassportToBeAdded: false,
        representativePassportNumber: 'gargkluerg',
        representativeSsn: '181194-1375',
        transactionPurpose: 'Tämä on Tammi Digitalin testi.',
        submitterName: 'Henri',
        trueBeneficiaryReason: BeneficiaryReason.OwnershipOrControl,
        verification: true
    }); */
    const [form, setForm] = React.useState({
        beneficialOwners: BeneficialOwner.Other,
        beneficiaries: [] as (Beneficiary & { id: string })[],
        businessOutsideEurope: false,
        businessOutsideEuropeCountries: new Set<string>([]),
        companyId: '',
        concernGraphToBeAdded: false,
        controlOf25Percent: false,
        name: '',
        politicallyExposedPersons: [] as (PoliticallyExposedPerson & { id: string })[],
        publicSignificantTaskDuring12Months: false,
        registeredToFinland: false,
        representatives: '',
        representativeNationality: '',
        representativePassportAuthority: '',
        representativePassportToBeAdded: false,
        representativePassportNumber: '',
        representativeSsn: '',
        transactionPurpose: '',
        submitterName: '',
        trueBeneficiaryReason: BeneficiaryReason.OwnershipOrControl,
        verification: false
    });

    const [errorAttachments, setErrorAttachments] = React.useState('');
    const [errorBeneficiaries, setErrorBeneficiaries] = React.useState<{ [id: string]: BeneficiaryError }>({});
    const [errorConcernGraphToBeAdded, setErrorConcernGraphToBeAdded] = React.useState('');
    const [errorName, setErrorName] = React.useState('');
    const [errorCompanyId, setErrorCompanyId] = React.useState('');
    const [errorPoliticallyExposedPersons, setErrorPoliticallyExposedPersons] = React.useState<{ [id: string]: PoliticallyExposedPersonError }>({});
    const [errorRepresentatives, setErrorRepresentatives] = React.useState('');
    const [errorRepresentativeSsn, setErrorRepresentativeSsn] = React.useState('');
    const [errorRepresentativeNationality, setErrorRepresentativeNationality] = React.useState('');
    const [errorRepresentativePassportAuthority, setErrorRepresentativePassportAuthority] = React.useState('');
    const [errorRepresentativePassportNumber, setErrorRepresentativePassportNumber] = React.useState('');
    const [errorRepresentativePassportToBeAdded, setErrorRepresentativePassportToBeAdded] = React.useState('');
    const [errorSubmitterName, setErrorSubmitterName] = React.useState('');
    const [errorTransactionPurpose, setErrorTransactionPurpose] = React.useState('');
    const [errorVerification, setErrorVerification] = React.useState('');

    const [formError, setFormError] = React.useState('');

    const id = (key: keyof typeof form) => key;
    const setFormField = (property: keyof typeof form, value: typeof form[typeof property]) =>
        () => setForm({ ...form, [property]: value });
    const setTextField = (property: keyof typeof form) => (value: string) =>
        setForm({ ...form, [property]: value });

    const emailLink = <a href={'mailto:' + contactEmail}>{contactEmail}</a>;

    return (
        <div className="App">
            <TitleMain content='Asiakastietolomake – Know Your Customer Form (KYC)' />

            <Paragraph>Kiitos, että valitsitte CBRE:n yhteistyökumppaniksenne. Pyydämme teitä ystävällisesti vastaamaan alla esitettyihin kysymyksiin ja vahvistamaan antamanne tiedot oikeiksi. Asiakkaan tuntemistietoja ja muita henkilötietoja voidaan käyttää rahanpesun ja terrorismin rahoittamisen estämiseen, paljastamiseen ja selvittämiseen sekä rahanpesun ja terrorismin rahoittamisen ja sen rikoksen, jolla rahanpesun tai terrorismin rahoittamisen kohteena oleva omaisuus tai rikoshyöty on saatu, tutkintaan saattamista varten.</Paragraph>
            <Paragraph>Olettehan yhteydessä osoitteeseen  <a href="mailto:finland.compliance@cbre.com">finland.compliance@cbre.com</a>, jos teillä on kysymyksiä.</Paragraph>

            <hr />

            <Paragraph>Thank you for choosing CBRE as your service provider. We kindly ask you to answer the below questions and confirm the given information as correct. Know Your Customer information can be used to prevent, expose and detect money laundering and terrorist financing and to report such conduct and the underlying crimes to the appropriate authorities.</Paragraph>
            <Paragraph>Please contact <a href="mailto:finland.compliance@cbre.com">finland.compliance@cbre.com</a>, in case of any questions.</Paragraph>

            <TitleFormSection content='Yhteisön perustiedot / Basic Company Information' />

            <FieldContainer>
                <Label content='Nimi / Name:' />
                <FieldSingleLineText name={id('name')} onChange={setTextField('name')} value={form.name} style={{ maxWidth: 360 }} />
                <Error text={errorName} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Y-tunnus / Business ID:' />
                <FieldSingleLineText name={id('companyId')} onChange={setTextField('companyId')} value={form.companyId} style={{ maxWidth: 240 }} />
                <Error text={errorCompanyId} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Edustaja(t) / Representative(s):' />
                <FieldSingleLineText name={id('representatives')} onChange={setTextField('representatives')} value={form.representatives} style={{ maxWidth: 480 }} />
                <Error text={errorRepresentatives} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Edustajan passin numero ja myöntäjä / Representative’s passport number and granting authority:' />
                <div style={{ display: 'grid', gridTemplateColumns: '49% 2% 49%' }}>
                    <div>
                        <FieldSingleLineText name={id('representativePassportNumber')} onChange={setTextField('representativePassportNumber')} value={form.representativePassportNumber} style={{ maxWidth: 360 }} />
                        <Error text={errorRepresentativePassportNumber} />
                    </div>
                    <div style={{ gridColumn: '3 / 4' }}>
                        <FieldSingleLineText name={id('representativePassportAuthority')} onChange={setTextField('representativePassportAuthority')} value={form.representativePassportAuthority} style={{ maxWidth: 360 }} />
                        <Error text={errorRepresentativePassportAuthority} />
                    </div>
                </div>
            </FieldContainer>

            <FieldContainer>
                <FieldCheckboxWithLabel
                    name={id('representativePassportToBeAdded')}
                    onChange={setFormField('representativePassportToBeAdded', !form.representativePassportToBeAdded)}
                    text='Liitän edustajan passikopion tämän lomakkeen lopussa / I will attach the representatives passport copy at the end of this form'
                    value={form.representativePassportToBeAdded} />
                <Error text={errorRepresentativePassportToBeAdded} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Edustajan henkilötunnus / Representative’s personal identification number:' />
                <FieldSingleLineText name={id('representativeSsn')} onChange={setTextField('representativeSsn')} value={form.representativeSsn} style={{ maxWidth: 240 }} />
                <Error text={errorRepresentativeSsn} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Edustajan kansalaisuus / Representative’s nationality:' />
                <FieldSingleLineText name={id('representativeNationality')} onChange={setTextField('representativeNationality')} value={form.representativeNationality} style={{ maxWidth: 280 }} />
                <Error text={errorRepresentativeNationality} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Onko yhteisöllänne liiketoimintaa ja/tai asiakkaita Euroopan unionin ja Euroopan talousalueen ulkopuolella? / Does your company conduct business outside the EU or EEA?' />
            </FieldContainer>
            <FieldContainer>
                <FieldRadioButtonWithLabel
                    onChange={() => {
                        setForm({
                            ...form,
                            businessOutsideEurope: true,
                            businessOutsideEuropeCountries: new Set(['none'])
                        });
                    }}
                    text='Kyllä / Yes'
                    value={form.businessOutsideEurope} />
                <FieldRadioButtonWithLabel
                    onChange={() => {
                        setForm({
                            ...form,
                            businessOutsideEurope: false,
                            businessOutsideEuropeCountries: new Set([])
                        });
                    }}
                    text='Ei / No'
                    value={!form.businessOutsideEurope} />
            </FieldContainer>

            <FieldContainer>
                {form.businessOutsideEurope &&
                    [
                        ...CountriesSelected.map(country =>
                            <div key={country.value} style={{ marginBottom: 4 }}>
                                <FieldCheckboxWithLabel
                                    name={`country-${country.value}`}
                                    onChange={() => setForm({
                                        ...form,
                                        businessOutsideEuropeCountries: TammiSet.removeElement(TammiSet.toggleElement(form.businessOutsideEuropeCountries, country.value), 'none')
                                    })}
                                    text={country.name}
                                    value={form.businessOutsideEuropeCountries.has(country.value)} />
                            </div>
                        ),
                        <FieldCheckboxWithLabel
                            name='none'
                            key='none'
                            onChange={setFormField('businessOutsideEuropeCountries', new Set(['none']))}
                            text='Ei missään edellä mainituista maista / None of the above'
                            value={form.businessOutsideEuropeCountries.has('none')} />
                    ]
                }
            </FieldContainer>

            <FieldContainer>
                <FieldCheckboxWithLabel
                    name={id('concernGraphToBeAdded')}
                    onChange={setFormField('concernGraphToBeAdded', !form.concernGraphToBeAdded)}
                    text='Liitän yrityksen konsernikaavion tämän lomakkeen lopussa / I will attach the company’s group structure chart at the end of this form'
                    value={form.concernGraphToBeAdded} />
                <Error text={errorConcernGraphToBeAdded} />
            </FieldContainer>

            <TitleFormSection content='Yhteisön tosiasialliset edunsaajat / Ultimate Beneficial Owners' />

            <FieldContainer>
                <Label content='Yhtiömme on rekisteröitynyt Suomeen ja olemme ilmoittaneet kaikki yhteisömme tosiasialliset edunsaajat kaupparekisteriin ja ko. tiedot ovat ajan tasaiset. / Our company is registered in Finland and we have declared our ultimate beneficial owners to the trade register and the informaiton is up-to-date.' />
            </FieldContainer>
            <FieldContainer>
                <FieldRadioButtonWithLabel
                    onChange={setFormField('registeredToFinland', true)}
                    text='Kyllä / Yes'
                    value={form.registeredToFinland} />
                <FieldRadioButtonWithLabel
                    onChange={setFormField('registeredToFinland', false)}
                    text='Ei / No'
                    value={!form.registeredToFinland} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Onko yhdellä tai useammalla henkilöllä, suoraan tai välillisesti, yli 25 % omistus tai äänivalta yhteisössänne? / Does one or more person directly or indirectly own or control over 25% of your company?' />
            </FieldContainer>
            <FieldContainer>
                <FieldRadioButtonWithLabel
                    onChange={setFormField('controlOf25Percent', true)}
                    text='Kyllä / Yes'
                    value={form.controlOf25Percent} />
                <FieldRadioButtonWithLabel
                    onChange={setFormField('controlOf25Percent', false)}
                    text='Ei / No'
                    value={!form.controlOf25Percent} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Yhteisössämme ei ole edellisen kohdan mukaisia tosiasiallisia edunsaajia, joten pidämme yhteisömme tosiasiallisina edunsaajina seuraavia henkilöitä / Our company does not have any ultimate beneficial owners, as described above, and we deem our ultimate beneficial owners to be:' />
            </FieldContainer>
            <FieldContainer>
                <FieldRadioButtonWithLabel
                    onChange={setFormField('beneficialOwners', BeneficialOwner.BoardOfDirectors)}
                    text='Yhteisömme hallituksen jäseniä / The members of our board of directors'
                    value={form.beneficialOwners === BeneficialOwner.BoardOfDirectors} />
                <FieldRadioButtonWithLabel
                    onChange={setFormField('beneficialOwners', BeneficialOwner.BoardOfDirectorsAndCeo)}
                    text='Yhteisömme hallituksen jäseniä ja toimitusjohtajaa / The members of our board of directors and managing director'
                    value={form.beneficialOwners === BeneficialOwner.BoardOfDirectorsAndCeo} />
                <FieldRadioButtonWithLabel
                    onChange={setFormField('beneficialOwners', BeneficialOwner.GeneralOrManagingPartnet)}
                    text='Yhteisömme vastuunalaisia yhtiömiehiä / General or managing partnet in a limited partnership'
                    value={form.beneficialOwners === BeneficialOwner.GeneralOrManagingPartnet} />
                <FieldRadioButtonWithLabel
                    onChange={setFormField('beneficialOwners', BeneficialOwner.Other)}
                    text='Muuta henkilöä / Other person'
                    value={form.beneficialOwners === BeneficialOwner.Other} />
            </FieldContainer>

            <TitleFormSection content='Tosiasialliset edunsaajat / True beneficial owners' />

            <FieldContainer>
                <ButtonPrimary text='Lisää edunsaaja / Add a beneficial owner' onClick={() => {
                    const id = uuidv4();
                    setErrorBeneficiaries({ ...errorBeneficiaries, [id]: BeneficiaryErrorInitialValue });
                    setForm({ ...form, beneficiaries: [...form.beneficiaries, { id, ...BenefiaryInitialValue }] });
                }} />
            </FieldContainer>

            <FieldContainer>
                {form.beneficiaries.length === 0 &&
                    <LabelMini content='Ei edunsaajia listattuna tällä hetkellä. / No beneficial owners listed at the moment.' />
                }
                {form.beneficiaries.map(beneficiary =>
                    <div key={beneficiary.id} style={{ marginBottom: 4 }}>
                        <BeneficiaryAccordion
                            id={beneficiary.id}
                            data={beneficiary}
                            error={errorBeneficiaries[beneficiary.id]}
                            onRemove={() => {
                                setErrorBeneficiaries(TammiObject.omitKey(errorBeneficiaries, beneficiary.id));
                                setFormField('beneficiaries', TammiArray.findElementFirstAndRemove(form.beneficiaries, b => b.id === beneficiary.id))();
                            }}
                            onValuesChange={data => {
                                setForm({
                                    ...form,
                                    beneficiaries: TammiArray.findElementFirstAndReplace(form.beneficiaries, b => b.id === beneficiary.id, data)
                                });
                            }}
                            openOnInit={true} />
                    </div>
                )}
            </FieldContainer>

            <TitleFormSection content='Yhteisön poliittisesti vaikutusvaltaiset henkilöt (PEP) / Politically exposed persons of the company (PEP)' />

            <FieldContainer>
                <Label content='Onko yhteisönne edustaja tai tosiasiallinen edunsaaja tai tällaisen henkilön perheenjäsen tai yhtiökumppani toiminut viimeisen 12 kuukauden aikana jossain merkittävässä julkisessa tehtävässä (https://www.finlex.fi/fi/laki/ajantasa/2019/20190610)? / Has a representative or a beneficial owner of your company (business partner or mamily member) held a public office during the past 12 months?' />
            </FieldContainer>
            <FieldContainer>
                <FieldRadioButtonWithLabel
                    onChange={setFormField('publicSignificantTaskDuring12Months', true)}
                    text='Kyllä / Yes'
                    value={form.publicSignificantTaskDuring12Months} />
                <FieldRadioButtonWithLabel
                    onChange={setFormField('publicSignificantTaskDuring12Months', false)}
                    text='Ei / No'
                    value={!form.publicSignificantTaskDuring12Months} />
            </FieldContainer>

            {form.publicSignificantTaskDuring12Months &&
                <>
                    <FieldContainer>
                        <ButtonPrimary text='Lisää henkilö / Add a person ' onClick={() => {
                            const id = uuidv4();
                            setErrorPoliticallyExposedPersons({ ...errorPoliticallyExposedPersons, [id]: PoliticallyExposedPersonErrorInitialValue });
                            setForm({ ...form, politicallyExposedPersons: [...form.politicallyExposedPersons, { id, ...PoliticallyExposedPersonInitialValue }] });
                        }} />
                    </FieldContainer>

                    <FieldContainer>
                        {form.politicallyExposedPersons.length === 0 &&
                            <LabelMini content='Ei poliittisesti vaikutusvaltaisia henkiöitä listattuna tällä hetkellä. / No politically exposed persons listed at the moment.' />
                        }
                        {form.politicallyExposedPersons.map(person =>
                            <div key={person.id} style={{ marginBottom: 4 }}>
                                <PoliticallyExposedPersonAccordion
                                    id={person.id}
                                    data={person}
                                    error={errorPoliticallyExposedPersons[person.id]}
                                    onRemove={() => {
                                        setErrorPoliticallyExposedPersons(TammiObject.omitKey(errorPoliticallyExposedPersons, person.id));
                                        setFormField('politicallyExposedPersons', TammiArray.findElementFirstAndRemove(form.politicallyExposedPersons, b => b.id === person.id))();
                                    }}
                                    onValuesChange={data => {
                                        setForm({
                                            ...form,
                                            politicallyExposedPersons: TammiArray.findElementFirstAndReplace(form.politicallyExposedPersons, b => b.id === person.id, data)
                                        });
                                    }}
                                    openOnInit={true} />
                            </div>
                        )}
                    </FieldContainer>
                </>
            }

            <TitleFormSection content='Liiketoimen tarkoitus / Purpose of the transaction' />

            <FieldContainer>
                <FieldMultilineText
                    name={id('transactionPurpose')}
                    onChange={setTextField('transactionPurpose')}
                    value={form.transactionPurpose} />
                <Error text={errorTransactionPurpose} />
            </FieldContainer>

            <TitleFormSection content='Liitteet / Attachments' />
            <FieldContainer>
                <FileUpload
                    allowedMimeTypes={[FileUploadMimeType.Pdf]}
                    descriptionForButton='Tai valitse tiedostot (.pdf) laitteeltasi / Or select files (.pdf) from your device'
                    descriptionForDrop='Pudota liitetiedostot (.pdf) tähän / Drop attachment files (.pdf) here'
                    summaryOfFiles={totalSize => `Tiedostokoko yhteensä / File size in total ${totalSize}`}
                    onChange={setAttachments} />
            </FieldContainer>
            <Error text={errorAttachments} />

            <FieldContainer>
                <FieldCheckboxWithLabel
                    name={id('verification')}
                    onChange={setFormField('verification', !form.verification)}
                    text='Vahvistan tiedot oikeiksi yhtiöni puolesta ja sen, että minulla on oikeus tehdä näin / I verify the information to be correct on behalf of my company and that I have the right to do so'
                    value={form.verification} />
                <Error text={errorVerification} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Nimi / Name:' />
                <FieldSingleLineText
                    enabled={form.verification}
                    name={id('submitterName')}
                    onChange={setTextField('submitterName')}
                    value={form.submitterName}
                    style={{ maxWidth: 320 }} />
                <Error text={errorSubmitterName} />
            </FieldContainer>

            <FieldContainer>
                <Error text={formError} />
            </FieldContainer>

            <FieldContainer>
                <ButtonPrimary text='Lähetä' onClick={async () => {
                    let formHasErrors = false;

                    type Validator = (value: any) => string;
                    type Callback = (value: string) => void;

                    const attachmentsValidator = (value: typeof attachments) => {
                        if (form.concernGraphToBeAdded && form.representativePassportToBeAdded && value.length < 2) {
                            return 'Edustajan passikopio ja yrityksen konsernikaavio ovat valittu liitettäväksi, mutta niistä molemmat tulee liittää lomakkeeseen / The representatives passport copy and the company\'s group structure have been chosen to be attached, but both of them are to be attached to the form.';
                        }
                        else if (form.representativePassportToBeAdded && value.length === 0) {
                            return 'Edustajan passikopio on valittu liitettäväksi, mutta yhtään liitettä ei ole liitetty lomakkeeseen / The representatives passport copy has been chosen to be attached, but there is no attachment provided in the form.';
                        }
                        else if (form.concernGraphToBeAdded && value.length === 0) {
                            return 'Yrityksen konsernikaavio on valittu liitettäväksi, mutta yhtään liitettä ei ole liitetty lomakkeeseen / The company\' group structure has been chosen to be attached, but there is no attachment provided in the form.';
                        }
                        return '';
                    }

                    const validators: [any, Validator, Callback][] = [
                        [attachments, attachmentsValidator, setErrorAttachments],
                        [form.name, TammiFormValidation.name(2), setErrorName],
                        [form.companyId, TammiFormValidation.name(4), setErrorCompanyId],
                        [form.representatives, TammiFormValidation.name(2), setErrorRepresentatives],
                        [form.representativeSsn, TammiFormValidation.string(3, 32), setErrorRepresentativeSsn],
                        [form.representativePassportAuthority, TammiFormValidation.name(1), setErrorRepresentativePassportAuthority],
                        [form.representativePassportNumber, TammiFormValidation.name(1), setErrorRepresentativePassportNumber],
                        [form.representativeNationality, TammiFormValidation.name(3), setErrorRepresentativeNationality],
                        [form.submitterName, TammiFormValidation.name(3), setErrorSubmitterName],
                        [form.transactionPurpose, TammiFormValidation.string(1), setErrorTransactionPurpose],
                        [form.verification, TammiFormValidation.boolean('Tiedot täytyy vahvistaa oikeiksi / Information must be verified to be correct..', true), setErrorVerification]
                    ];

                    for (const [value, validator, errorCallback] of validators) {
                        const validationResult = validator(value);

                        if (validationResult.length > 0) {
                            formHasErrors = true;
                            errorCallback(validationResult);
                        }
                        else {
                            errorCallback('');
                        }
                    }

                    const birthDateValidator = (type: BenefiaryIdentificationType) => (value: TammiDatetime) => {
                        if (type === BenefiaryIdentificationType.SocialSecurityNumber) {
                            return '';
                        }
                        else {
                            return TammiFormValidation.date(TammiDatetimeFormat.DateMySQL)(value.format(TammiDatetimeFormat.DateMySQL));
                        }
                    };
                    const reasonDescriptionValidator = (reason: BeneficiaryReason) => (value: string) => {
                        if (reason === BeneficiaryReason.OwnershipOrControl) {
                            return '';
                        }
                        else {
                            return TammiFormValidation.string(4)(value);
                        }
                    }
                    const reasonPercentageValidator = (reason: BeneficiaryReason) => (value: string) => {
                        if (reason === BeneficiaryReason.OtherBasis) {
                            return '';
                        }
                        else {
                            return TammiFormValidation.numericString()(value);
                        }
                    }
                    const ssnValidator = (type: BenefiaryIdentificationType) => (value: string) => {
                        if (type === BenefiaryIdentificationType.BirthDate) {
                            return '';
                        }
                        else {
                            return TammiFormValidation.string(3, 32)(value);
                        }
                    }

                    let formHasErrorsWithBeneficiaries = false;
                    let errorObjsForBeneficiaries: { [id: string]: BeneficiaryError } = {};

                    for (const { identificationType, ...beneficiary } of form.beneficiaries) {
                        let errorObj: Partial<BeneficiaryError> = {};

                        const validators: [keyof BeneficiaryError, Validator][] = [
                            ['birthDate', birthDateValidator(identificationType)],
                            ['email', TammiFormValidation.email()],
                            ['name', TammiFormValidation.name(2)],
                            ['nationality', TammiFormValidation.name(3)],
                            ['phoneNumber', TammiFormValidation.phoneNumber()],
                            ['reasonDescription', reasonDescriptionValidator(beneficiary.reason)],
                            ['reasonPercentage', reasonPercentageValidator(beneficiary.reason)],
                            ['ssn', ssnValidator(identificationType)]
                        ];

                        for (const [beneficiaryKey, validator] of validators) {
                            const validationResult = validator(beneficiary[beneficiaryKey]);

                            if (validationResult.length > 0) {
                                formHasErrorsWithBeneficiaries = true;
                                errorObj[beneficiaryKey] = validationResult;
                            }
                            else {
                                errorObj[beneficiaryKey] = '';
                            }
                        }

                        errorObjsForBeneficiaries[beneficiary.id] = { ...errorBeneficiaries[beneficiary.id], ...errorObj };
                    }

                    let formHasErrorsWithPeps = false;
                    let errorObjsForPeps: { [id: string]: PoliticallyExposedPersonError } = {};

                    for (const pep of form.politicallyExposedPersons) {
                        let errorObj: Partial<PoliticallyExposedPersonError> = {};

                        const newPep = { ...pep, birthDate: pep.birthDate.format(TammiDatetimeFormat.DateMySQL) };

                        const validators: [keyof PoliticallyExposedPersonError, Validator][] = [
                            ['birthDate', TammiFormValidation.date(TammiDatetimeFormat.DateMySQL)],
                            ['name', TammiFormValidation.name(2)],
                            ['nationality', TammiFormValidation.name(3)]
                        ];

                        for (const [pepKey, validator] of validators) {
                            const validationResult = validator(newPep[pepKey]);

                            if (validationResult.length > 0) {
                                formHasErrorsWithPeps = true;
                                errorObj[pepKey] = validationResult;
                            }
                            else {
                                errorObj[pepKey] = '';
                            }
                        }

                        errorObjsForPeps[newPep.id] = { ...errorPoliticallyExposedPersons[newPep.id], ...errorObj };
                    }

                    setErrorBeneficiaries({
                        ...errorBeneficiaries,
                        ...errorObjsForBeneficiaries
                    });
                    setErrorPoliticallyExposedPersons({
                        ...errorPoliticallyExposedPersons,
                        ...errorObjsForPeps
                    });

                    if (formHasErrors || formHasErrorsWithBeneficiaries || formHasErrorsWithPeps) {
                        setFormError('Tällä lomakkeella on virheitä. Tarkista lomake. / There are errors on this form. Please check the form.');
                        return;
                    }
                    else {
                        setFormError('');
                    }

                    const requestData: FormPostRequestBody = {
                        ...form,
                        attachments: await Promise.all(attachments.map(async attachment => ({
                            content: attachment.data,
                            name: attachment.name
                        }))),
                        beneficiaries: form.beneficiaries.map(person => ({
                            ...TammiObject.omitKeys(person, 'id', 'identificationType'),
                            birthDate: person.identificationType === BenefiaryIdentificationType.BirthDate && person.birthDate
                                ? person.birthDate.withoutTime.format(TammiDatetimeFormat.ISO_8601_PreserveTime)
                                : '',
                            reasonDescription: person.reason === BeneficiaryReason.OtherBasis
                                ? person.reasonDescription
                                : '',
                            reasonPercentage: person.reason === BeneficiaryReason.OwnershipOrControl
                                ? TammiNumber.parseFromString(person.reasonPercentage) || 0
                                : 0,
                            ssn: person.identificationType === BenefiaryIdentificationType.SocialSecurityNumber
                                ? person.ssn
                                : ''
                        })),
                        businessOutsideEuropeCountries: Array.from(form.businessOutsideEuropeCountries),
                        representativesPassportAuthority: form.representativePassportAuthority,
                        representativesPassportNumber: form.representativePassportNumber,
                        politicallyExposedPersons: form.politicallyExposedPersons.map(person => ({
                            ...TammiObject.omitKeys(person, 'id'),
                            birthDate: person.birthDate.withoutTime.format(TammiDatetimeFormat.ISO_8601_PreserveTime)
                        }))
                    };

                    const url = TammiUrl.make({
                        domain: serverUrl,
                        path: 'api/forms'
                    });

                    const formPostResponse: FormPostResponseFailure | FormPostResponseSuccess | null = await fetch(url, {
                        method: 'POST',
                        body: JSON.stringify(requestData),
                        headers: { 'Content-Type': 'application/json' }
                    })
                        .then(async res => await res.json())
                        .catch(err => {
                            console.error('Form post error', err);
                            return null;
                        });

                    if (formPostResponse !== null && formPostResponse.status === 'ok') {
                        onSuccess();
                    }
                    else {
                        console.error('Lähettämisessä tapahtui virhe', formPostResponse);

                        if (formPostResponse && formPostResponse.type === FormPostResponseErrorMessageEnumType.FailedToSetDocuments) {
                            setFormError('Tiedostojen lataamisessa tapahtui virhe / En error occured with file upload.');
                        }
                        else {
                            setFormError('Lähettämisessä tapahtui virhe / En error occured while sending the form.');
                        }
                    }
                }} />
            </FieldContainer>
        </div>
    );
}
