export const TammiObject = {
    omitKey: <T, K extends keyof T>(value: T, key: K): Omit<T, K> => {
        let result = { ...value };
        delete result[key];
        return result;
    },
    omitKeys: <T, K extends keyof T>(value: T, ...keys: K[]): Omit<T, K> => {
        let result: any = {};

        for (const key in value) {
            if (keys.find(k => k as any === key) === undefined) {
                result[key] = value[key];
            }
        }

        return result;
    },
    pickKeys: <T, K extends keyof T>(value: T, ...keys: K[]): Pick<T, K> => {
        let result: any = {};

        for (const key of keys) {
            result[key] = value[key];
        }

        return result;
    }
};