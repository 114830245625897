interface TammiUrlOptions {
    domain: string;
    path: string;
    queryParams?: { [key: string]: string };
}

export const TammiUrl = {
    make(options: TammiUrlOptions) {
        const d = TammiUrl.removeTrailingSlash(options.domain);
        return `${d}/${options.path}${options.queryParams ? TammiUrl.queryParamsToString(options.queryParams) : ''}`;
    },
    queryParamsToString: (value: { [key: string]: string }) => {
        return Object.entries(value)
            .map(([key, value]) => value.length > 0 ? `${key}=${value}` : key)
            .join('&');
    },
    removeTrailingSlash: (value: string) => {
        return value.replace(/\\|\/$/, '');
    }
};