import React from "react";
import { TammiDatetime, TammiDatetimeLocale } from "../modules/TammiDatetime";
import { TammiRange } from "../modules/TammiRange";
import { FieldSelect } from "./Field";
import { LabelMini } from "./Label";

const currentYear = TammiDatetime.currentDate(TammiDatetimeLocale.EuropeFinland).year;
const years = TammiRange.makeContinuous(currentYear, 125, -1);

const months = TammiRange.makeContinuous(1, 12, 1);

interface SelectsDatepickerParams {
    enabled?: boolean;
    onChange: (value: TammiDatetime) => void;
    value: TammiDatetime;
}

export const SelectsDatepicker = ({ enabled, onChange, value }: SelectsDatepickerParams) => {
    const [day, setDay] = React.useState(value.day);
    const [month, setMonth] = React.useState(value.month);
    const [year, setYear] = React.useState(value.year);

    React.useEffect(() => {
        onChange(new TammiDatetime(new Date(year, month - 1, day)));
    }, [day, month, year]);

    React.useEffect(() => {
        setDay(value.day);
        setMonth(value.month);
        setYear(value.year);
    }, []);

    const opacityStyle = { opacity: enabled === false ? 0.5 : 1 };

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '80px 104px 100px', gridTemplateRows: 'auto auto' }}>
            <FieldSelect
                enabled={enabled}
                onChange={value => setDay(value)}
                options={TammiRange
                    .makeContinuous(1, new TammiDatetime(new Date(year, month - 1)).daysInMonth, 1)
                    .map(day => ({ name: day.toString(), value: day }))}
                value={day} />
            <FieldSelect
                enabled={enabled}
                onChange={value => setMonth(value)}
                options={months.map(month => ({ name: month.toString(), value: month }))}
                value={month} />
            <FieldSelect
                enabled={enabled}
                onChange={value => setYear(value)}
                options={years.map(year => ({ name: year.toString(), value: year }))}
                value={year} />
            <div>
                <LabelMini content='Päivä / day' style={opacityStyle} />
            </div>
            <div>
                <LabelMini content='Kuukausi / month' style={opacityStyle} />
            </div>
            <div>
                <LabelMini content='Vuosi / year' style={opacityStyle} />
            </div>
        </div>
    );
}