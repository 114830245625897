import { TammiDatetime } from "../modules/TammiDatetime";
import { BeneficialOwner, Beneficiary } from "./Beneficiary";
import { PoliticallyExposedPerson } from "./PoliticallyExposedPerson";

/**
 * Replaces properties with date type to string types
 * @todo Change name for the better
 */
export type ReplacementForDate<T> = {
    [P in keyof T]: T[P] extends TammiDatetime | null
    ? string | null
    : T[P] extends TammiDatetime
    ? string
    : T[P]
}

export interface FormAttachment {
    content: string;
    name: string;
}

export type FormBeneficiaryWithReplacements =
    Omit<Beneficiary, 'reasonPercentage'> &
    { reasonPercentage: number };

export interface FormPostRequestBody {
    attachments: FormAttachment[];
    beneficialOwners: BeneficialOwner;
    beneficiaries: ReplacementForDate<Omit<FormBeneficiaryWithReplacements, 'identificationType'>>[];
    businessOutsideEuropeCountries: string[];
    companyId: string;
    controlOf25Percent: boolean;
    name: string;
    politicallyExposedPersons: ReplacementForDate<PoliticallyExposedPerson>[];
    registeredToFinland: boolean;
    representativeNationality: string;
    representatives: string;
    representativesPassportAuthority: string;
    representativesPassportNumber: string;
    representativeSsn: string;
    submitterName: string;
    transactionPurpose: string;
}
export enum FormPostResponseErrorMessageEnumType {
    FailedToSetDocuments = 'failed-to-set-documents',
    InvalidAttachment = 'invalid-attachment',
    InvalidAttachmentName = 'invalid-attachment-name',
    InvalidAttachments = 'invalid-attachments',
    InvalidBeneficialOwners = 'invalid-beneficial-owners',
    InvalidBeneficiaries = 'invalid-beneficiaries',
    InvalidBeneficiaryBirthdate = 'invalid-beneficiary-birthdate',
    InvalidBeneficiaryEmail = 'invalid-beneficiary-email',
    InvalidBeneficiaryName = 'invalid-beneficiary-name',
    InvalidBeneficiaryNationality = 'invalid-beneficiary-nationality',
    InvalidBeneficiaryPhoneNumber = 'invalid-beneficiary-phone-number',
    InvalidBeneficiarySsn = 'invalid-beneficiary-ssn',
    InvalidBeneficiaryReason = 'invalid-beneficiary-reason',
    InvalidBeneficiaryReasonDescription = 'invalid-beneficiary-reason-description',
    InvalidBeneficiaryReasonPercentage = "invalid-beneficiary-reason-percentage",
    InvalidCompanyId = 'invalid-company-id',
    InvalidControlOf25Percent = 'invalid-control-of-25-percent',
    InvalidCountries = 'invalid-countries',
    InvalidName = 'invalid-name',
    InvalidPEPBirthdate = 'invalid-pep-birthdate',
    InvalidPEPs = 'invalid-peps',
    InvalidPEPName = 'invalid-pep-name',
    InvalidPEPNationality = 'invalid-pep-nationality',
    InvalidRegisteredToFinland = 'invalid-registered-to-finland',
    InvalidRepresentatives = 'invalid-representatives',
    InvalidRepresentativesNationality = 'invalid-representatives-nationality',
    InvalidRepresentativesPassportAuthority = "invalid-representatives-passport-authority",
    InvalidRepresentativesPassportNumber = "invalid-representatives-passport-number",
    InvalidRepresentativesSsn = 'invalid-representatives-ssn',
    InvalidSubmitterName = 'invalid-submitter-name',
    InvalidTransactionPurpose = 'invalid-transaction-purpose',
    ServerError = 'server-error'
}

export interface FormPostResponseFailure {
    details?: any;
    type: FormPostResponseErrorMessageEnumType;
    status: 'error';
}
export interface FormPostResponseSuccess {
    status: 'ok';
}