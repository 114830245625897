import "./Title.scss";

interface TitleParams {
    content: string;
}

export const TitleBanner = ({ content }: TitleParams) => {
    return (
        <div className='TitleBanner-container'>
            <h1>{content}</h1>
        </div>
    );
}
export const TitleFormSection = ({ content }: TitleParams) => {
    return (
        <div className='TitleFormSection-container'>
            <h2>{content}</h2>
        </div>
    );
}
export const TitleMain = ({ content }: TitleParams) => {
    return <h1 className='TitleMain'>{content}</h1>;
}