const clone = <T>(set: Set<T>) => new Set(Array.from(set));

export const TammiSet = {
    addElement: <T>(set: Set<T>, value: T) => {
        const newSet = clone(set);
        newSet.add(value);
        return newSet;
    },
    empty: <T>() => new Set<T>(),
    hasMany: <T>(set: Set<T>, values: T[]) => {
        for (const value of values) {
            if (!set.has(value)) {
                return false;
            }
        }

        return true;
    },
    removeElement: <T>(set: Set<T>, value: T) => {
        const newSet = clone(set);
        newSet.delete(value);
        return newSet;
    },
    toggleElement: <T>(set: Set<T>, value: T) => {
        const newSet = clone(set);

        if (newSet.has(value)) {
            newSet.delete(value);
        }
        else {
            newSet.add(value);
        }

        return newSet;
    }
}