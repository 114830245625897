import React from 'react';
import './App.css';
import { FormPage } from './pages/FormPage';
import { SuccessPage } from './pages/SuccessPage';

enum Page {
  Form,
  Success
}

const contactEmail = 'atte.sorvettula@cbre.com';
const serverUrl = process.env.REACT_APP_SERVER_URL || null;

function App() {
  const [page, setPage] = React.useState(Page.Form);

  return (
    <>
      {!serverUrl && <p>Palvelimen osoitetta ei ole asetettu.</p>}
      {serverUrl && page === Page.Form && <FormPage contactEmail={contactEmail} onSuccess={() => setPage(Page.Success)} serverUrl={serverUrl} />}
      {page === Page.Success && <SuccessPage contactEmail={contactEmail} />}
    </>
  );
}

export default App;
