import React from "react";
import { TammiFormMessage } from "../modules/TammiForm";
import { PoliticallyExposedPerson, PoliticallyExposedPersonError } from "../values/PoliticallyExposedPerson";
import { Accordion } from "./Accordion";
import { FieldSingleLineText } from "./Field";
import { FieldContainer } from "./FieldContainer";
import { Label } from "./Label";
import { SelectsDatepicker } from "./SelectsDatepicker";

const Error = TammiFormMessage.Error({
    className: 'App-Error'
});

interface PoliticallyExposedPersonAccordionParams {
    data: PoliticallyExposedPerson & { readonly id: string };
    error: PoliticallyExposedPersonError;
    id: string;
    onRemove: () => void;
    onValuesChange: (value: PoliticallyExposedPersonAccordionParams['data']) => void;
    openOnInit: boolean;
}

export const PoliticallyExposedPersonAccordion = ({ data, error, id, onRemove, onValuesChange, openOnInit }: PoliticallyExposedPersonAccordionParams) => {
    const [open, setOpen] = React.useState(false);

    const key = (key: keyof typeof data) => `pep-${key}-${id}`;
    const getSetFieldWithParam = (property: keyof typeof data) => (value: typeof data[typeof property]) => {
        onValuesChange({ ...data, [property]: value });
    }

    return (
        <Accordion
            header={data.name.length > 0 ? data.name : 'Uusi poliittisesti vaikutusvaltainen henkilö / New politically exposed person'}
            onOpenChange={value => setOpen(value)}
            open={open}
            onRemove={onRemove}
            openOnInit={openOnInit}
        >
            <FieldContainer>
                <Label content='Nimi / Name:' />
                <FieldSingleLineText name={key('name')} onChange={getSetFieldWithParam('name')} value={data.name} style={{ maxWidth: 360 }} />
                <Error text={error.name} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Syntymäaika / Date of birth:' />
                <SelectsDatepicker
                    onChange={getSetFieldWithParam('birthDate')}
                    value={data.birthDate} />
                <Error text={error.birthDate} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Kansalaisuus / Nationality:' />
                <FieldSingleLineText name={key('nationality')} onChange={getSetFieldWithParam('nationality')} value={data.nationality} style={{ maxWidth: 280 }} />
                <Error text={error.nationality} />
            </FieldContainer>
        </Accordion>
    );
}