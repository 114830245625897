import React from "react";
import "./Field.scss";
import checkMarkIcon from "../assets/check-mark.svg";
import { Label } from "./Label";
import { TammiDatetime, TammiDatetimeLocale } from "../modules/TammiDatetime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface FieldCheckboxParams {
    name: string;
    onChange: () => void;
    value: boolean;
}
interface FieldCheckboxWithLabelParams extends FieldCheckboxParams {
    text: string;
}

export const FieldCheckbox = ({ name, onChange, value }: FieldCheckboxParams) => {
    return (
        <div onClick={onChange} className='FieldCheckbox-container'>
            <img className={value ? '' : 'disabled'} src={checkMarkIcon} alt='' />
        </div>
    );
}
export const FieldCheckboxWithLabel = ({ name, onChange, text, value }: FieldCheckboxWithLabelParams) => {
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '37px auto' }}>
            <div>
                <FieldCheckbox name={name} onChange={onChange} value={value} />
            </div>
            <div>
                <Label content={text} />
            </div>
        </div>
    );
}

interface FieldDateParams {
    onChange: (value: TammiDatetime) => void;
    value: TammiDatetime;
}

export const FieldDate = ({ onChange, value }: FieldDateParams) => {
    return (
        <div className='FieldDate-container'>
            <DatePicker
                selected={value.rawDate}
                onChange={date => {
                    if (date instanceof Date) {
                        onChange(new TammiDatetime(date, TammiDatetimeLocale.EuropeFinland));
                    }
                }} />
        </div>
    );
}

interface FieldRadioButtonParams {
    onChange: () => void;
    value: boolean;
}
interface FieldRadioButtonWithLabelParams extends FieldRadioButtonParams {
    text: string;
}

export const FieldRadioButton = ({ onChange, value }: FieldRadioButtonParams) => {
    return (
        <div onClick={onChange} className='FieldRadioButton-container'>
            <div className={value ? '' : 'disabled'}></div>
        </div>
    );
}
export const FieldRadioButtonWithLabel = ({ onChange, text, value }: FieldRadioButtonWithLabelParams) => {
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '37px auto', marginBottom: 5 }}>
            <div>
                <FieldRadioButton onChange={onChange} value={value} />
            </div>
            <div style={{ marginTop: 2 }}>
                <Label content={text} style={{ fontSize: 12 }} />
            </div>
        </div>
    );
}

interface FieldMultilineTextParams {
    enabled?: boolean;
    name: string;
    onChange: (value: string) => void;
    value: string;
}

export const FieldMultilineText = ({ enabled, name, onChange, value }: FieldMultilineTextParams) => {
    return (
        <div className='FieldMultilineText-container'>
            <textarea
                name={name}
                disabled={enabled === false}
                onChange={event => onChange(event.target.value)}
                value={value}
                style={{ width: '100%' }}></textarea>
        </div>
    );
}

interface FieldSelectParams<ValueType> {
    enabled?: boolean;
    onChange: (value: ValueType) => void;
    options: { name: string, value: ValueType }[];
    value: ValueType;
}

export const FieldSelect = function <ValueType>({ enabled, onChange, options, value }: FieldSelectParams<ValueType>) {
    return (
        <div className='FieldSelect-container'>
            <select disabled={enabled === false} onChange={event => onChange(event.target.value as any)} value={value as any}>
                {options.map((option, i) => <option key={`${option.value}-${i}`} value={option.value as any}>{option.name}</option>)}
            </select>
        </div>
    );
}

interface FieldSingleLineTextParams {
    enabled?: boolean;
    name: string;
    onChange: (value: string) => void;
    style?: React.CSSProperties;
    value: string;
}

export const FieldSingleLineText = ({ enabled, onChange, name, style, value }: FieldSingleLineTextParams) => {
    return (
        <div className='FieldSingleLineText-container'>
            <input name={name} disabled={enabled === false} onChange={event => onChange(event.target.value)} type='text' value={value} style={style} />
        </div>
    );
}
