import { TammiDatetime, TammiDatetimeLocale } from "../modules/TammiDatetime";

export interface PoliticallyExposedPerson {
    birthDate: TammiDatetime;
    nationality: string;
    name: string;
}

export interface PoliticallyExposedPersonError {
    birthDate: string;
    nationality: string;
    name: string;
}
export const PoliticallyExposedPersonErrorInitialValue: PoliticallyExposedPersonError = {
    birthDate: '',
    name: '',
    nationality: ''
};

export const PoliticallyExposedPersonInitialValue: PoliticallyExposedPerson = {
    birthDate: TammiDatetime.currentDate(TammiDatetimeLocale.EuropeFinland),
    nationality: '',
    name: ''
};