import "./Label.scss";

interface LabelParams {
    content: string;
    style?: React.CSSProperties;
}

export const Label = ({ content }: LabelParams) => {
    return <label className='Label'>{content}</label>;
}
export const LabelMini = ({ content, style }: LabelParams) => {
    return <label className='LabelMini' style={style}>{content}</label>;
}