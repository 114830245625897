export const TammiArray = {
    findElementFirstAndReplace: <T>(value: T[], callback: (value: T) => boolean, replacementValue: T) => {
        const index = value.findIndex(callback);
        return (index !== -1) ? TammiArray.replaceElementByIndex(value, index, replacementValue) : value;
    },
    findElementFirstAndRemove: <T>(value: T[], callback: (value: T) => boolean) => {
        const index = value.findIndex(callback);
        return (index !== -1) ? TammiArray.removeElementByIndex(value, index) : value;
    },
    firstElement: <T>(value: T[]) => {
        return value[0] || null;
    },
    replaceElementByIndex: <T>(value: T[], index: number, replacementValue: T) => {
        let result = [...value];
        result[index] = replacementValue;
        return result;
    },
    removeElementByIndex: <T>(value: T[], index: number) => {
        return value.filter((_, i) => i !== index);
    }
};

export const TammiArraySortCallbacks = {
    sortByStringPropertyInAlphabeticalOrder: <T>(propertyName: keyof T) => (a: T, b: T) => {
        if (a[propertyName] > b[propertyName]) {
            return 1;
        }
        else if (a[propertyName] < b[propertyName]) {
            return -1;
        }
        else {
            return 0;
        }
    },
    sortByCallbackReturningStringValueInAlphabeticalOrder: <T>(callback: (value: T) => string) => (a: T, b: T) => {
        const aResult = callback(a);
        const bResult = callback(b);

        if (aResult > bResult) {
            return 1;
        }
        else if (aResult < bResult) {
            return -1;
        }
        else {
            return 0;
        }
    },
    sortByStringValuesInAlphabeticalOrder: (a: string, b: string) => {
        if (a > b) {
            return 1;
        }
        else if (a < b) {
            return -1;
        }
        else {
            return 0;
        }
    }
}