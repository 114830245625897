import React from "react";
import { TammiDatetime, TammiDatetimeFormat, TammiDatetimeLocale } from "../modules/TammiDatetime";
import { TammiFormMessage, TammiFormValidation } from "../modules/TammiForm";
import { Beneficiary, BenefiaryIdentificationType, BeneficiaryReason, BeneficiaryError } from "../values/Beneficiary";
import { Accordion } from "./Accordion";
import { FieldSingleLineText, FieldRadioButtonWithLabel, FieldMultilineText } from "./Field";
import { FieldContainer } from "./FieldContainer";
import { Label } from "./Label";
import { SelectsDatepicker } from "./SelectsDatepicker";

const Error = TammiFormMessage.Error({
    className: 'App-Error'
});

interface BeneficiaryAccordionParams {
    id: string;
    data: Beneficiary & { readonly id: string };
    error: BeneficiaryError;
    onRemove: () => void;
    onValuesChange: (value: BeneficiaryAccordionParams['data']) => void;
    openOnInit: boolean;
}

export const BeneficiaryAccordion = ({ data, error, id, onRemove, onValuesChange, openOnInit }: BeneficiaryAccordionParams) => {
    const [open, setOpen] = React.useState(false);

    const key = (key: keyof typeof data) => `beneficiary-${key}-${id}`
    const setFormField = (property: keyof typeof data, value: typeof data[typeof property]) => () => {
        onValuesChange({ ...data, [property]: value });
    }
    const setTextField = (property: keyof typeof data) => (value: string) => {
        onValuesChange({ ...data, [property]: value });
    }

    return (
        <Accordion
            header={data.name.length > 0 ? data.name : 'Uusi edunsaaja / New beneficial owner'}
            onOpenChange={value => setOpen(value)}
            open={open}
            onRemove={onRemove}
            openOnInit={openOnInit}
        >
            <FieldContainer>
                <Label content='Nimi / Name:' />
                <FieldSingleLineText
                    name={key('name')}
                    onChange={setTextField('name')}
                    value={data.name}
                    style={{ maxWidth: 360 }} />
            </FieldContainer>
            <Error text={error.name} />

            <div className='App-col-2'>
                <FieldContainer>
                    <FieldRadioButtonWithLabel
                        onChange={() => {
                            onValuesChange({
                                ...data,
                                identificationType: BenefiaryIdentificationType.SocialSecurityNumber
                            });
                        }}
                        text='Henkilötunnus / Personal identification number:'
                        value={data.identificationType === BenefiaryIdentificationType.SocialSecurityNumber} />
                    <FieldSingleLineText
                        name={key('ssn')}
                        enabled={data.identificationType === BenefiaryIdentificationType.SocialSecurityNumber}
                        onChange={setTextField('ssn')} value={data.ssn}
                        style={{ maxWidth: '90%' }} />
                    <Error text={error.ssn} />
                </FieldContainer>

                <FieldContainer>
                    <FieldRadioButtonWithLabel
                        onChange={() => {
                            onValuesChange({
                                ...data,
                                identificationType: BenefiaryIdentificationType.BirthDate
                            });
                        }}
                        text='Syntymäaika / Date of birth:'
                        value={data.identificationType === BenefiaryIdentificationType.BirthDate} />
                    <SelectsDatepicker
                        enabled={data.identificationType === BenefiaryIdentificationType.BirthDate}
                        onChange={value => setFormField('birthDate', value)()}
                        value={data.birthDate} />
                    <Error text={error.birthDate} />
                </FieldContainer>
            </div>

            <FieldContainer>
                <Label content='Kansalaisuus / Nationality:' />
                <FieldSingleLineText
                    name={key('nationality')}
                    onChange={setTextField('nationality')}
                    value={data.nationality}
                    style={{ maxWidth: 280 }} />
                <Error text={error.nationality} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Sähköposti / Email:' />
                <FieldSingleLineText
                    name={key('email')}
                    onChange={setTextField('email')}
                    value={data.email}
                    style={{ maxWidth: 512 }} />
                <Error text={error.email} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Puhelinnumero / Phone number:' />
                <FieldSingleLineText
                    name={key('phoneNumber')}
                    onChange={setTextField('phoneNumber')}
                    value={data.phoneNumber}
                    style={{ maxWidth: 320 }} />
                <Error text={error.phoneNumber} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Miksi henkilö on tosiasiallinen edunsaaja? / Why is this person a beneficial owner?' />
                <FieldRadioButtonWithLabel
                    onChange={setFormField('reason', BeneficiaryReason.OwnershipOrControl)}
                    text='Omistus tai äänivalta / Ownership or control'
                    value={data.reason === BeneficiaryReason.OwnershipOrControl} />
            </FieldContainer>

            <FieldContainer>
                <Label content='Suuruus / Percentage:' />
                <FieldSingleLineText
                    enabled={data.reason === BeneficiaryReason.OwnershipOrControl}
                    name={key('reasonPercentage')}
                    onChange={setTextField('reasonPercentage')}
                    value={data.reasonPercentage}
                    style={{ maxWidth: 160 }} />
                <Error text={error.reasonPercentage} />
            </FieldContainer>

            <FieldContainer>
                <FieldRadioButtonWithLabel
                    onChange={setFormField('reason', BeneficiaryReason.OtherBasis)}
                    text='Muu peruste, mikä / Other basis, what:'
                    value={data.reason === BeneficiaryReason.OtherBasis} />
            </FieldContainer>

            <FieldContainer>
                <FieldMultilineText
                    name={key('reasonDescription')}
                    enabled={data.reason === BeneficiaryReason.OtherBasis}
                    onChange={setTextField('reasonDescription')}
                    value={data.reasonDescription} />
                <Error text={error.reasonDescription} />
            </FieldContainer>
        </Accordion>
    );
}