import React from 'react';
import { TammiArray } from './TammiArray';
import { TammiDatetime, TammiDatetimeFormat } from './TammiDatetime';
import "./TammiForm.scss";

interface TammiFormMessageError {
    text: string;
}
interface TammiFormMessageErrorOptions {
    className: string;
}

export const TammiFormMessage = {
    Error: ({ className }: TammiFormMessageErrorOptions) => ({ text }: TammiFormMessageError) => {
        const enabledClass = text.length > 0 ? ['enabled'] : [];
        return (
            <div className={['TammiFormMessage-Error', className, ...enabledClass].join(' ')} style={{ display: text.length > 0 ? 'block' : 'none' }}>
                <p>{text}</p>
            </div>
        );
    }
};

export const TammiFormValidation = {
    boolean: function <T>(textOnError: string, requiredValue?: boolean) {
        return (value: T) => {
            if (requiredValue === undefined) {
                if (!(typeof value === 'boolean')) {
                    return textOnError;
                }
            }
            else {
                if (!(typeof value === 'boolean' && value === requiredValue)) {
                    return textOnError;
                }
            }

            return '';
        };
    },
    date: function <T>(format: TammiDatetimeFormat.DateMySQL) {
        return (value: T) => {
            if (!(typeof value === 'string' && TammiDatetime.fromFormat(format, value))) {
                return 'Päivämäärä virheellisessä muodossa / Date in invalid format.'
            }

            return '';
        };
    },
    email: function <T>() {
        return (value: T) => {
            if (typeof value !== 'string') {
                return 'Merkkijonoa ei annettu / No string given.';
            }
            else if (value.length < 2) {
                return 'Sisältöä ei ole tarpeeksi / Not enough content.';
            }
            else if (!/^\S+@\S+\.\S+/.test(value)) {
                return 'Sähköposti oli virheellisessä muodossa / Email was invalid format.';
            }

            return '';
        };
    },
    name: function <T>(minLength: number) {
        return (value: T) => {
            if (typeof value !== 'string') {
                return 'Merkkijonoa ei annettu / No string given.';
            }
            else if (value.length < minLength) {
                return 'Sisältöä ei ole tarpeeksi / Not enough content.';
            }

            return '';
        };
    },
    numericString: function <T>() {
        return (value: T) => {
            if (typeof value !== 'string') {
                return 'Merkkijonoa ei annettu / No string given.';
            }
            else if (isNaN(parseFloat(value.replace(',', '.')))) {
                return 'Lukua ei annettu oikeassa muodossa / Number was not given in correct format.';
            }

            return '';
        };
    },
    phoneNumber: function <T>() {
        return (value: T) => {
            if (typeof value !== 'string') {
                return 'Merkkijonoa ei annettu / No string given.';
            }
            else if (!/^(\d|\-|\s){5,}$/.test(value)) {
                return 'Puhelinnumero on virheellisessä muodossa / Phone number is in incorrect format.';
            }

            return '';
        };
    },
    socialSecurityNumber: function <T>() {
        return (value: T) => {
            if (typeof value !== 'string') {
                return 'Merkkijonoa ei annettu / No string given.';
            }
            else if (!/^[0-9]{6}(\-|\+|A)[0-9]{3}[0-9A-FHJ-NPR-Y]$/.test(value)) {
                return 'Henkilötunnus annettiin virheellisessä muodossa / Social security number was given in incorrect format.';
            }

            return '';
        };
    },
    string: function <T>(minLength: number, maxLength?: number) {
        return (value: T) => {
            if (typeof value !== 'string') {
                return 'Merkkijonoa ei annettu / No string given.';
            }
            else if (value.length < minLength) {
                return 'Sisältöä ei ole tarpeeksi/ Not enough content.';
            }
            else if (maxLength && value.length > maxLength) {
                return 'Sisältöä on liian paljon/ There is too much content.';
            }

            return '';
        };
    }
}