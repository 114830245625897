export class TammiDatetime {
    private ignoreTime: boolean = false;

    constructor(
        public readonly rawDate: Date,
        public readonly locale: TammiDatetimeLocale = TammiDatetimeLocale.EuropeFinland) { }

    get asBuiltinDate() {
        return this.rawDate;
    }
    get day() {
        return this.rawDate.getDate();
    }
    get daysInMonth() {
        return new Date(this.year, this.month, 0).getDate();
    }
    get daysLeftInMonth() {
        return this.daysInMonth - this.day;
    }
    get dayWithLeadingZeros() {
        return this.day.toString().padStart(2, '0');
    }
    get hour() {
        return this.rawDate.getHours();
    }
    get hourWithLeadingZeros() {
        return this.hour.toString().padStart(2, '0');
    }
    get isWeekendDay() {
        const day = this.rawDate.getDay();
        return day === 0 || day === 6;
    }
    get minute() {
        return this.rawDate.getMinutes();
    }
    get minuteWithLeadingZeros() {
        return this.minute.toString().padStart(2, '0');
    }
    get month() {
        return this.rawDate.getMonth() + 1;
    }
    get monthWithLeadingZeros() {
        return this.month.toString().padStart(2, '0');
    }
    get second() {
        return this.rawDate.getSeconds();
    }
    get secondWithLeadingZeros() {
        return this.second.toString().padStart(2, '0');
    }
    get withoutTime() {
        let tmp = new Date(this.rawDate);
        let result = new TammiDatetime(tmp, this.locale);
        result.ignoreTime = true;
        return result;
    }
    get year() {
        return this.rawDate.getFullYear();
    }

    addDays(value: number) {
        let result = new Date(this.rawDate);
        result.setDate(result.getDate() + value);
        return new TammiDatetime(result, this.locale);
    }
    after(value: TammiDatetime) {
        return this.rawDate.getTime() > value.rawDate.getTime();
    }
    before(value: TammiDatetime) {
        return this.rawDate.getTime() < value.rawDate.getTime();
    }
    clone() {
        return new TammiDatetime(this.rawDate, this.locale);
    }
    format(format: string) {
        switch (format) {
            case TammiDatetimeFormat.ISO_8601:
                const result = this.rawDate.toISOString();

                if (this.ignoreTime) {
                    const [date, time] = result.split('T');
                    const timeReplaced = time.replace(/\d/g, '0');
                    return `${date}T${timeReplaced}`;
                }
                else {
                    return result;
                }
            case TammiDatetimeFormat.ISO_8601_PreserveTime:
                const v = this.rawDate;
                return `${this.year}-${this.monthWithLeadingZeros}-${this.dayWithLeadingZeros}T${this.hourWithLeadingZeros}:${this.minuteWithLeadingZeros}:${this.secondWithLeadingZeros}.000Z`;
            default:
                return format.replace(/D|d|H|i|M|m|s|y|Y/g, match => {
                    switch (match) {
                        case 'D':
                            return this.rawDate.getDate().toString().padStart(2, '0');
                        case 'd':
                            return this.rawDate.getDate().toString();
                        case 'H':
                            return this.rawDate.getHours().toString().padStart(2, '0');
                        case 'i':
                            return this.rawDate.getMinutes().toString().padStart(2, '0');
                        case 'M':
                            return (this.rawDate.getMonth() + 1).toString().padStart(2, '0');
                        case 'm':
                            return (this.rawDate.getMonth() + 1).toString();
                        case 's':
                            return this.rawDate.getSeconds().toString().padStart(2, '0');
                        case 'Y':
                            return this.rawDate.getFullYear().toString().padStart(2, '0');
                        case 'y':
                            return this.rawDate.getFullYear().toString();
                        default:
                            return match;
                    }
                });
        }
    }
    setDay(value: number) {
        let result = this.clone();
        result.rawDate.setDate(value);
        return result;
    }
    setMonth(value: number) {
        let result = this.clone();
        result.rawDate.setMonth(value - 1);
        return result;
    }

    public static currentDate(locale: TammiDatetimeLocale) {
        return new TammiDatetime(new Date(), locale).withoutTime;
    }
    public static currentDatetime(locale: TammiDatetimeLocale) {
        return new TammiDatetime(new Date(), locale);
    }
    public static fromFormat(format: TammiDatetimeFormat, value: string) {
        switch (format) {
            case TammiDatetimeFormat.DateMySQL: {
                const matches = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/.test(value);

                if (matches) {
                    const [year, month, day] = value.split('-');
                    return new TammiDatetime(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)));
                }
                else {
                    return null;
                }
            }
            case TammiDatetimeFormat.DatetimeMySQL: {
                const matches = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}\s[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(value);

                if (matches) {
                    const [year, month, day, hours, minutes, seconds] = value.split(/-|\s|:/g);
                    return new TammiDatetime(new Date(
                        parseInt(year), parseInt(month) - 1, parseInt(day),
                        parseInt(hours), parseInt(minutes), parseInt(seconds)
                    ));
                }
                else {
                    return null;
                }
            }
            case TammiDatetimeFormat.ISO_8601:
                const milliseconds = Date.parse(value);
                return isNaN(milliseconds) ? null : new TammiDatetime(new Date(milliseconds));
            default:
                return null;
        }
    }
    public static matchesFormat(format: TammiDatetimeFormat, value: string | null | undefined) {
        return TammiDatetime.fromFormat(format, value || '') !== null;
    }
};
export enum TammiDatetimeFormat {
    DateFinnishNoLeadingZeros = 'd.m.Y',
    DateMySQL = 'Y-M-D',
    DatetimeMySQL = 'Y-M-D H:i:s',
    ISO_8601 = 'Y-M-DTH:I:S.NZ',
    ISO_8601_PreserveTime = 'Y-M-DTH:I:S'
}
export enum TammiDatetimeLocale {
    EuropeFinland
}