import React from "react";
import { Paragraph } from "../components/Paragraph";
import { TitleBanner } from "../components/Title";

interface SuccessPageParams {
    contactEmail: string;
}

export const SuccessPage = ({ contactEmail }: SuccessPageParams) => {
    const emailLink = <a href={'mailto:' + contactEmail}>{contactEmail}</a>;

    return (
        <div className="App-centered">
            <div className="App-centered-content">
                <TitleBanner content='Kiitos! / Thank you!' />

                <Paragraph>Kiitos, kun täytit lomakkeen. Jos sinulla on kysymyksiä lomakkeeseen liittyen, olethan yhteydessä CBRE:n Director of Legal & Compliance, Nordics Atte Sorvettulaan ({emailLink}).</Paragraph>

                <hr />

                <Paragraph>Thank you for filling in the form. In case of any questions, please contact CBRE’s Director of Legal & Compliance, Nordics Atte Sorvettula ({emailLink}).</Paragraph>
            </div>
        </div>
    );
}