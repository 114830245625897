import React from "react";
import "./Accordion.scss";

interface AccordionParams {
    children: React.ReactNode;
    header: string;
    onOpenChange: (value: boolean) => void;
    open: boolean;
    onRemove: () => void;
    openOnInit: boolean;
}

export const Accordion = ({ children, header, onOpenChange, open, onRemove, openOnInit }: AccordionParams) => {
    React.useEffect(() => {
        if (openOnInit) {
            setTimeout(() => {
                onOpenChange(true);
            }, 500);
        }
    }, []);

    return (
        <>
            <div data-status={open ? 'open' : 'closed'} className='Accordion-container' onClick={() => onOpenChange(!open)}>
                <p className='Accordion-header'>{header}</p>
                <p onClick={onRemove} className='Accordion-close-button'>+</p>
            </div>
            <div data-status={open ? 'open' : 'closed'} className='Accordion-content' style={{ maxHeight: open ? 1024 : 0 }}>
                {children}
            </div>
        </>
    );
}